<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="2" sm="2" xs="2">
            <h3 style="color: #e33354" class="ml-3">
              {{ $t("MENU.contact_form") }}
            </h3>
          </v-col>
          <v-col cols="12" md="2" sm="2" xs="2">
            <v-select
              v-model="contactFormType"
              :items="contactFormTypeCollection"
              :label="$t('FORM_INPUT_NAMES.type')"
              item-value="id"
              item-text="name"
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" md="3" sm="3" xs="4">
            <v-menu
              v-if="contactFormListCollection.length != 0"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="pb- pt-0"
                  v-model="formattedDates"
                  :label="$t('FORM_INPUT_NAMES.select_range')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRange"
                :first-day-of-week="1"
                range
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  {{ $t("FORMS.cancel") }}
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dateRange)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="selectedItems"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
        >
          <!-- <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template> -->
        </v-data-table>
        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="handleCloseDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>
        <InfoModalDialog
          :dialogInfo="dialogInfo"
          :info="info"
          @closeInfo="handleCloseInfo"
        >
        </InfoModalDialog>

        <v-row class="mt-5">
          <v-col cols="12" md="10" sm="10" xs="10">
            <v-select
              v-if="contactFormListCollection.length != 0"
              v-model="selected"
              :items="
                Object.keys(selectedKeys).map((key) => ({
                  text: key,
                  value: selectedKeys[key],
                }))
              "
              small-chips
              deletable-chips="true"
              :label="$t('FORMS.fields')"
              multiple
              solo
            >
              <template #selection="{ item }">
                <v-chip
                  color="bg-gray-100"
                  close
                  @click:close="deleteChip(item, selected)"
                  >{{ item.text }}</v-chip
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="2" sm="2" xs="2">
            <export-excel
              v-if="selectedItems.length != 0"
              class="btn"
              :data="json_data"
              :fields="json_fields"
              worksheet="contactForms"
              :name="exportName"
            >
              <v-btn color="primary" dark style="width: 100%!import">
                {{ $t("FORMS.excel_export") }}
              </v-btn>
            </export-excel>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SiteService from "@/core/services/site.service.js";
import ApiService from "@/core/services/api.service";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import InfoModalDialog from "@/view/components/InfoModalDialog";

export const ENDPOINT =
  "contactForms/admin/" + SiteService.getActiveSiteId() + "/contactForm/";

export const PERMISSION_TO = "contactForms.contactForm.";

export default {
  name: "ContactForms",
  components: {
    DeleteModalDialog,
    InfoModalDialog,
  },
  mixins: [listModelsMixins],
  data() {
    return {
      search: "",
      routePath: "/cms/contactForms/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.created"),
          value: "created_at",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },
        /*{
          text: this.$t("TABLE.company"),
          value: "company_name",
        },*/
        {
          text: "Email",
          value: "email",
        },
        /*{
          text: this.$t("CONTACT_FORM.referer"),
          value: "referer",
        },*/
        /*{
          text: this.$t("TABLE.phone"),
          value: "phone",
        },*/
        // { text: "Status", value: "status" },
        // {
        //   text: this.$t("TABLE.actions"),
        //   value: "actions",
        //   sortable: false,
        //   width: "100px",
        // },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      // editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      dialogInfo: false,
      loader: false,
      loadingTable: true,
      endPoint: ENDPOINT,
      contactFormType: null,
      contactFormTypeCollection: [],
      contactFormListCollection: [],
      info: {},

      menu: false,
      dateRange: [],

      except_fields: ["id", "custom_fields"],
      selectedKeys: [],
      selected: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },

  computed: {
    ...mapGetters(["statuses"]),

    formattedDates() {
      if (!this.dateRange) return [];
      if (this.dateRange.length == 2) {
        return this.dateRange[0] + " - " + this.dateRange[1];
      }
      return this.dateRange;
    },

    exportName() {
      if (this.dateRange.length != 2) {
        return "contactExport.xls";
      }
      return `contactExport_${this.dateRange[0]}__${this.dateRange[1]}.xls`;
    },

    selectedItems() {
      if (this.dateRange.length == 2) {
        return this.contactFormListCollection.filter((item) => {
          return (
            this.dateRange[0] <= item.created_at.substring(0, 10) &&
            item.created_at.substring(0, 10) <= this.dateRange[1]
          );
        });
      }

      if (this.dateRange.length == 1) {
        return [];
      }
      return this.contactFormListCollection;
    },

    json_data() {
      if (this.selectedItems.length == 0) return [];
      return this.selectedItems;
    },

    json_fields() {
      if (this.selectedItems.length == 0) return [];

      let object = {};

      this.selected.forEach((key) => (object[this.$t("EXCELL." + key)] = key));

      return object;
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.showModal(id);
        }
      },
      deep: true,
      immediate: true,
    },

    contactFormType(val) {
      if (val) {
        this.dateRange = [];
        this.loadingTable = true;
        ApiService.get(
          "contactForms/admin/" +
            SiteService.getActiveSiteId() +
            "/contactForm/" +
            val +
            "/contactFormData"
        )
          .then(({ data }) => {
            this.exportName = "contactExport.xls";

            this.contactFormListCollection = data.map((item) => {
              item.name = "";
              item.name += item.first_name ?? "";
              item.name += item.last_name ? " " + item.last_name : "";

              if (!item.last_name || !item.first_name) {
                delete item.last_name;
                delete item.first_name;
              } else {
                delete item.name;
              }
              return item;
            });

            if (this.contactFormListCollection.length > 0) {
              let keys = Object.keys(this.selectedItems[0]).filter(
                (key) => !this.except_fields.includes(key)
              );

              let object = {};

              console.log("this.selectedItems[0]", this.selectedItems[0], keys);
              keys.forEach((key) => (object[this.$t("EXCELL." + key)] = key));
              this.selectedKeys = object;
              this.selected = keys;
            } else {
              this.selectedKeys = [];
              this.selected = [];
            }
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loadingTable = false;
          });
      }
    },
  },

  methods: {
    ...mapActions([]),
    fetchModel() {
      ApiService.get(
        "contactForms/admin/" + SiteService.getActiveSiteId() + "/contactForm"
      )
        .then(({ data }) => {
          this.contactFormTypeCollection = data.map((item) => {
            item.name = this.$helpers.getTranslated(item.translations).name;
            return item;
          });
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    showModal(id) {
      this.loader = true;
      ApiService.get(
        "contactForms/admin/" +
          SiteService.getActiveSiteId() +
          "/contactFormData/" +
          id
      )
        .then(({ data }) => {
          this.info.title = this.$t("CONTACT_FORM.contact_info");

          data.name = "";
          data.name += data.first_name ?? "";
          data.name += data.last_name ? " " + data.last_name : "";

          if (!data.last_name || !data.first_name) {
            delete data.last_name;
            delete data.first_name;
          } else {
            delete data.name;
          }

          if (data.custom_fields) {
            this.info.data = { ...data, ...data.custom_fields };
          } else {
            this.info.data = data;
          }

          this.dialogInfo = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    handleCloseInfo() {
      this.dialogInfo = false;
      this.info = {};
      this.$router.push({ name: "contactForms" });
    },

    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.value) {
          array.splice(i, 1);
        }
      }
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel();
  },
};
</script>
